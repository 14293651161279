import { render, staticRenderFns } from "./FillblanksModule.vue?vue&type=template&id=3bf6906c&scoped=true&"
import script from "./FillblanksModule.vue?vue&type=script&lang=js&"
export * from "./FillblanksModule.vue?vue&type=script&lang=js&"
import style0 from "./FillblanksModule.vue?vue&type=style&index=0&id=3bf6906c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf6906c",
  null
  
)

export default component.exports